import React, { forwardRef, useEffect, useState } from "react";
// import { InputSelect } from "@/stories/forms";
// import { InputSelect } from "@/stories/forms";
import { ChevronBottom, FiChevronUp } from "./Icon";
import { InputSelect } from "@bluesilodev/timhutcomponents";
import moment from "moment/moment";

const ShiftChild = ({
  item,
  handleClickFormShift,
  visible,
  dataDate,
  indexDate,
}) => {
  return (
    item.shiftData.length > 0 &&
    item.shiftData.map((shift, index) => {
      // Calculate the total number of employees needed
      const totalEmployeesNeeded =
        shift.employees?.reduce(
          (total, employee) => total + employee.employeesNeeded,
          0
        ) || 0;

      // Count the number of assigned employees
      const assignedEmployeesCount = shift?.countLength || 0;
      const result =
        indexDate <= dataDate?.length - 1 &&
        shift?.repeatDate
          ?.map((d) => moment(d).format("DD-MM-YYYY"))
          ?.includes(moment(item?.date).format("DD-MM-YYYY"));
      if (!result) return null;

      const handleClick = () => handleClickFormShift(shift, shift?.uId);

      return (
        <div key={index} className="w-[210px] flex flex-col gap-x-5 p-1">
          <div
            onClick={handleClick}
            style={{ backgroundColor: shift?.color }}
            className={`w-full h-[74px] ${
              !shift?.isShowBox && "invisible"
            } rounded-md cursor-pointer flex flex-col justify-center items-center text-[13px]`}
          >
            <h1 className="font-semibold">{shift?.shiftName}</h1>
            <div>
              {shift?.startTime} - {shift?.endTime}
            </div>
            <div className="bg-white px-1 rounded-md">
              {assignedEmployeesCount}/{totalEmployeesNeeded}
            </div>
          </div>
        </div>
      );
    })
  );
};

const CalendarMap = forwardRef(
  (
    {
      dataDate,
      handleRef,
      widthScroll,
      filteredData,
      handleClickFormShift,
      locationData,
      onChangeLocation,
      locationState,
      dataAssignShift,
    },
    ref
  ) => {
    // const [visible, setVisible] = useState(2);
    const [visible, setVisible] = useState(true);
    const [screenDetect, setScreenDetect] = useState(window.innerWidth);
    const handleShowData = () => {
      /*  if (visible < 3) {
        setVisible(filteredData.length);
      } */
      setVisible(false);
    };

    const handleHiddenData = () => {
      /*   if (visible >= 3) {
        setVisible(2);
      } */
      setVisible(true);
    };

    // GET THE LENGTH ARRAY SHIFT
    const totalLengthShift =
      filteredData.length > 0 &&
      filteredData.reduce((acc, obj) => {
        return obj.shiftData.length;
      }, 0);

    // AT LEAST ONE TRUE isShowBox TO SHOW EXPAND
    const isShowExpand =
      filteredData.length > 0 &&
      filteredData?.some((item) => {
        return (
          item.shiftData.length > 0 &&
          item.shiftData.some((shift) => shift.isShowBox === true)
        );
      });
    useEffect(() => {
      const handleResize = () => {
        setScreenDetect(window.innerWidth);
      };

      handleResize(); // Panggil sekali untuk mendapatkan ukuran awal
      window.addEventListener("resize", handleResize);

      return () => {
        window.removeEventListener("resize", handleResize); // Bersihkan listener saat komponen di-unmount
      };
    }, []);
    return (
      <div
        /*   className={`flex border-b border-gray-400 ${
          filteredData.length < 2 && "min-h-[258px]"
        }`} */
        className={`flex border-b border-gray-400 overflow-hidden `}
      >
        {/* ONLY ONE  */}
        <div className="flex flex-col border-r border-gray-400 w-[400px]">
          <div className="w-full h-[67px] rounded-tl-md flex items-center pl-3  border-b border-gray-400 ">
            <h1 className="font-semibold text-[14px]">Employee</h1>
          </div>

          <div className={`w-[400px] mt-14 flex items-center pl-3`}>
            <InputSelect
              id="1"
              options={locationData || []}
              title={"Location"}
              // value={locationData.length > 0 && locationData[1]?.value}
              value={locationState}
              defaultValue={locationData[0]}
              classname={"w-[250px]"}
              // onChange={(e) => {
              //   setLocation(e.target.value);
              // }}
              onChange={onChangeLocation}
            />
          </div>
        </div>

        {/* MAPPING IN HERE */}

        <div className={`flex flex-col w-full  `}>
          <div
            /* className={`min-w-[${widthScroll}px] overflow-hidden flex   ${
              dataDate.length > 0
                ? "min-h-[258px]"
                : "border-b border-gray-400 "
            }`} */
            // className={`max-w-[${widthScroll}px] overflow-auto flex`}
            className={` overflow-hidden min-h-[230px] flex`}
            style={{
              maxWidth: `${screenDetect - 430}px`,
            }}
            ref={ref}
            onScroll={handleRef}
          >
            {dataDate.length === 0 && (
              <div className=" border-gray-400 h-[91px] w-full"></div>
            )}
            <div
              className={`flex min-w-[${widthScroll}px]  ${
                visible && " max-h-[230px] "
              }  `}
            >
              {/* SHIFT DATA TEMPLATE  */}
              {filteredData.length > 0 &&
                filteredData.map((item, indexDate) => {
                  const dateShift = new Date(item?.date);

                  const dayShortName = dateShift.toLocaleDateString("en-US", {
                    weekday: "short",
                  });

                  const getDate = dateShift.getDate();
                  const getMonth = dateShift.getMonth() + 1;

                  return (
                    <div
                      className={`flex flex-col border-r border-gray-400  flex-shrink-0 w-[211px] `}
                      key={indexDate}
                    >
                      <div
                        className={`w-[210.16px] min-h-[67px] border-b border-gray-400 rounded-tl-md flex flex-col justify-center items-center `}
                      >
                        <div>{dayShortName}</div>
                        <h1 className="font-semibold">
                          {getDate}/{getMonth}
                        </h1>
                      </div>

                      {item?.shiftData?.length === 0 && (
                        <div className="min-h-[191px]"></div>
                      )}

                      <div className="flex flex-col ">
                        <ShiftChild
                          handleClickFormShift={handleClickFormShift}
                          item={item}
                          visible={visible}
                          dataDate={dataDate}
                          indexDate={indexDate}
                        />
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>

          {/* {totalLengthShift > 2 && visible < 3 && isShowExpand && (
            <div
              onClick={handleShowData}
              className="border-t border-gray-400 h-[27px]  flex justify-center items-center cursor-pointer "
            >
              <ChevronBottom color={"black"} className={"w-[24px] h-[24px]"} />
            </div>
          )} */}
          {visible ? (
            <div
              onClick={handleShowData}
              className="border-t border-gray-400 h-[27px]  flex justify-center items-center cursor-pointer "
            >
              <ChevronBottom color={"black"} className={"w-[24px] h-[24px]"} />
            </div>
          ) : (
            <div
              onClick={handleHiddenData}
              className="border-t border-gray-400 h-[27px]  flex justify-center items-center cursor-pointer "
            >
              <FiChevronUp />
            </div>
          )}
          {/*  {totalLengthShift > 2 && visible >= 3 && isShowExpand && (
            <div
              onClick={handleHiddenData}
              className="border-t border-gray-400 h-[27px]  flex justify-center items-center cursor-pointer "
            >
              <FiChevronUp />
            </div>
          )} */}

          {dataDate.length === 0 && (
            <div className="border-b border-gray-400 h-full w-full"></div>
          )}
        </div>
      </div>
    );
  }
);

export default CalendarMap;
