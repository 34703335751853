import React, { useEffect, useState } from "react";
import ShiftEmployee from "./shiftAndAssign/ShiftEmployee";
import ShiftEmployeeContext from "./ShiftEmployeeContext";

const EmployeeMap = ({
  dataDate,
  handleRef,
  widthScroll,
  dataAssignShift = [], // Default to empty array
  dataEmployees = [], // Default to empty array
  filteredData = [], // Default to empty array
  locationState = "", // Default to empty string
  handleShowAssign,
  handleEditAssignShift,
  handleConflickAssign,
  roleUser,
  ref2,
  selectView,
}) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const allDataApi =
      dataEmployees?.length > 0 &&
      dataEmployees?.map((itemEmp) => {
        let filteredEmployee = itemEmp.employee;

        // CONDITION FOR SELECT LOCATION
        // if (locationState !== "") {
        //   filteredEmployee =
        //     itemEmp.employee.length > 0 &&
        //     itemEmp.employee.filter((item) => {
        //       return itemEmp.locationID === locationState;
        //     });
        // } else {
        //   filteredEmployee = itemEmp.employee;
        // }

        return {
          ...itemEmp,
          employee: filteredEmployee,
          shiftData: filteredData,
        };
      });

    setData(allDataApi);
  }, [dataDate, filteredData, dataEmployees, locationState, dataAssignShift]);

  // Fungsi untuk mencari total length terbesar
  function findMaxLength(data) {
    let maxLength = 0;

    data?.forEach((item) => {
      const assignLength =
        item.assignAndConflick && item.assignAndConflick.length;
      let cumulativeConflickLength = 0;

      // Iterasi setiap assignAndConflick
      item.assignAndConflick.forEach((conflick) => {
        // Tambahkan length conflickData kumulatif
        cumulativeConflickLength += conflick.conflickData.length;

        // Hitung total length dengan menambahkan cumulativeConflickLength
        const totalLength = assignLength + cumulativeConflickLength;

        // Update maxLength jika ditemukan total length lebih besar
        if (totalLength > maxLength) {
          maxLength = totalLength;
        }
      });
    });

    return maxLength;
  }

  function dataUpdateAssignShift(shift, itemEmp) {
    const empJobPosition =
      itemEmp?.userInformation &&
      itemEmp?.userInformation?.employeementDetail?.jobPosition;

    const allShift =
      shift?.shiftDetails.length > 0 &&
      shift?.shiftDetails.filter((detail) => {
        const employees = detail.employees.some(
          (emp) => emp.jobPosition === empJobPosition
        );

        return employees;
      });

    return allShift;
  }

  function hoverBaseonAssignShift(shift, itemEmp) {
    const empJobPosition =
      itemEmp?.userInformation &&
      itemEmp?.userInformation?.employeementDetail?.jobPosition;

    const assignShifts = Array.isArray(shift?.toAssignShift)
      ? shift?.toAssignShift
      : [];

    for (const detail of assignShifts) {
      const matchedAssignShifts =
        shift?.dataAssignShift?.length > 0 &&
        shift?.dataAssignShift?.filter(
          (assign) =>
            assign.shiftId === detail.uId &&
            assign.jobPosition === empJobPosition &&
            detail.employees.some(
              (emp) => emp.jobPosition === assign.jobPosition
            )
        );

      const status1 = detail.employees.some((emp) => {
        const isSameJob = emp.jobPosition === empJobPosition;
        const employeesNeeded = emp.employeesNeeded;

        // Gabungkan kondisi menjadi lebih sederhana
        if (!isSameJob) return false; // Job position tidak cocok

        if (matchedAssignShifts.length < employeesNeeded) {
          return true; // Masih butuh pekerja
        } else if (matchedAssignShifts.length === employeesNeeded) {
          return false; // Jumlah pekerja sudah terpenuhi
        }

        return !matchedAssignShifts; // Jika tidak ada shift yang cocok
      });

      // Jika `status1` menemukan kecocokan, langsung kembalikan hasilnya
      if (status1) return true;
    }

    // Jika tidak ada yang cocok, kembalikan false
    return false;
  }

  const forStructure =
    data?.length > 0 &&
    data?.map((all) => {
      const jobPosition = all.jobPosition;

      let lengtAssignShift = 0;

      // SHIFT DATA BASE ON DATA EMPLOYEE
      const filteredEmployee =
        all?.employee?.length > 0 &&
        all?.employee?.map((itemEmp, empIdx) => {
          let isAnyConflick = false;

          const empJobPosition =
            itemEmp?.userInformation &&
            itemEmp?.userInformation?.employeementDetail?.jobPosition;

          //  all.shiftData
          const shiftDatas =
            all?.shiftData.length > 0 &&
            all?.shiftData.map((shift) => {
              // Data TO ASSIGN
              const toAssignShift =
                shift.toAssignShift.length > 0 &&
                shift.toAssignShift.map((item) => {
                  return {
                    ...item,
                    date: shift.date,
                    jobPosition: jobPosition,
                  };
                });

              // DATA TO UPDATE ASSIGN
              const toUpdateAssignShift = dataUpdateAssignShift(shift, itemEmp);

              // FILTER EMPLOYEE JOBPOSITION AND ASSIGNSHIFT JOBPOSITION
              const filterBaseEmployeeAssign = hoverBaseonAssignShift(
                shift,
                itemEmp
              );

              // FILTER BASE ON JOBPOSITION EMPLOYEE
              const assignShiftBaseOnjob =
                toAssignShift?.length > 0 &&
                toAssignShift?.filter((item) => {
                  const matchedAssignShifts =
                    shift?.dataAssignShift?.length > 0 &&
                    shift?.dataAssignShift?.filter((assign) => {
                      return (
                        assign.shiftId === item.uId &&
                        assign.jobPosition === empJobPosition &&
                        item.employees.some(
                          (emp) => emp.jobPosition === assign.jobPosition
                        )
                      );
                    });

                  return matchedAssignShifts
                    ? item.employees.some(
                        (emp) =>
                          emp.jobPosition === empJobPosition &&
                          emp.employeesNeeded !== matchedAssignShifts.length
                      )
                    : item;
                });

              const filterBaseOnJobPosition =
                assignShiftBaseOnjob &&
                assignShiftBaseOnjob?.length > 0 &&
                assignShiftBaseOnjob?.map((shift) => {
                  const baseOn = shift.employees.some((job) => {
                    return job.jobPosition === empJobPosition;
                  });

                  return baseOn;
                });

              // FILTER TO SHOW ASSIGN
              const filterToAssign = dataAssignShift
                ? dataAssignShift?.length > 0 &&
                  dataAssignShift?.filter((assign) => {
                    const newDate = new Date(assign.date);
                    const shiftDate = new Date(shift.date);

                    return (
                      newDate.getDate() === shiftDate.getDate() &&
                      assign.userId === itemEmp.uId
                    );
                  })
                : {};

              // filter jam yang sama berdasarkan startTime and Endtime dan masukkan kedalam property conflickData

              const findConflickData = (() => {
                const seen = new Map();
                const result = [];

                filterToAssign?.length > 0 &&
                  filterToAssign?.forEach((item) => {
                    const key = `${item?.shiftDetails?.startTime}-${item?.shiftDetails?.endTime}`;

                    if (seen.has(key)) {
                      // if there is conflick data create property conflickData and push
                      const existingItem = seen.get(key);
                      existingItem.conflickData.push(item);
                    } else {
                      // if there is no conflick data just push
                      item.conflickData = [];
                      seen.set(key, item);
                      result.push(item);
                    }
                  });

                return result;
              })();

              // FIND CONFLICK DATA
              const hasConflick =
                findConflickData?.length > 0 &&
                findConflickData?.some((item) => {
                  return item.conflickData.length > 0;
                });

              if (hasConflick) {
                isAnyConflick = true;
              }

              return {
                ...shift,
                filterBaseOnJobPosition,
                // hoverBase: hover2,
                toAssignShift: assignShiftBaseOnjob,
                toUpdateAssignShift,
                assignAndConflick: findConflickData,
                lengthDataAssignShift: findConflickData.length,
                showHover: dataAssignShift && dataAssignShift.length > 0,
                hoverBaseAssignShift: filterBaseEmployeeAssign,
                // filterBaseOnLocation,
              };
            });

          // const ceckStatusHeight = shiftDatas.
          const findLenght = findMaxLength(shiftDatas);
          if (findLenght) {
            lengtAssignShift = findLenght;
          }

          return {
            ...itemEmp,
            shiftData: shiftDatas,
            isAnyConflick,
            // lengthEveryAssign: shiftDatas.assignAndConflick.length + 1,
            lengthEveryAssign: findLenght,
          };
        });

      return {
        jobPosition: all.jobPosition,
        jobPositionID: all.jobPositionID,
        organizationID: all.organizationId,
        employeeAssignShift: filteredEmployee,
        lengthAssign: lengtAssignShift,
      };
    });

  return (
    <ShiftEmployeeContext forStructure={forStructure}>
      <div className="flex flex-col w-full ">
        <ShiftEmployee
          forStructure={forStructure}
          dataDate={dataDate}
          handleConflickAssign={handleConflickAssign}
          handleEditAssignShift={handleEditAssignShift}
          handleRef={handleRef}
          handleShowAssign={handleShowAssign}
          ref={ref2}
          roleUser={roleUser}
          widthScroll={widthScroll}
          selectView={selectView}
        />
      </div>
    </ShiftEmployeeContext>
  );
};

export default EmployeeMap;
